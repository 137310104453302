<template>
  <v-snackbar :value="value" :bottom="true" :color="color" :timeout="300000">
    {{ text }}
    <v-btn dark icon @click="close(false)">
      <v-icon>{{ mdiClose }}</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mdiClose } from "@mdi/js";

export default {
  name: "AppSnackbar",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mdiClose,
    };
  },
  methods: {
    close(value) {
      this.$emit("input", value);
    },
  },
};
</script>
