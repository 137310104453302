<template>
  <v-container
    :class="loading ? 'progressheight' : '' + 'container-edit container--fluid'"
  >
    <v-row class="fill-height" align-content="center" justify="center">
      <v-col class="text-subtitle-1 text-center" cols="12"> </v-col>
      <v-col cols="6">
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          color="blue-grey accent-4"
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>
    <div v-if="!loading" class="siteEdit">
      <div class="nav col-3">
        <div class="navtitle">
          <v-icon>{{ mdiPlaylistEdit }}</v-icon>
          <span class="title">Sites</span>
        </div>
        <nav>
          <ul>
            <a v-for="(site, index) in sites" :key="index">
              <li
                :class="{ active: navActive === index }"
                @click="editItem(site, index)"
              >
                0{{ site._id }} -
                {{ site.title }}
              </li>
            </a>
          </ul>
        </nav>
      </div>
      <div class="form col-7">
        <div class="col-10 ma-auto forminputs">
          <div class="row">
            <div class="col">
              <label for="titulo">Titulo</label>
              <input type="text" v-model="edit.titulo" placeholder="" />
            </div>
          </div>
          <div class="row mt-0">
            <div class="col-6">
              <label for="cidade">Cidade</label>
              <input type="text" v-model="edit.cidade" placeholder="" />
            </div>
            <div class="col-6">
              <label for="estado">Estado</label>
              <input type="text" v-model="edit.estado" placeholder="" />
            </div>
          </div>
          <div class="row mt-0">
            <div class="col-8">
              <label for="endereco">Endereço</label>
              <input type="text" v-model="edit.endereco" placeholder="" />
            </div>
            <div class="col-4">
              <label for="tempodeamostragem">Tempo de amostragem </label>
              <input
                type="text"
                v-model="edit.tempoAmostragem"
                placeholder=""
              />
            </div>
            <!--
              4 swith para tensão
              3 para temperatura
             -->
            <!-- <v-sheet width="100%" height="100%" class="pa-12"> -->
            <div class="row">
              <div class="col-6 pl-6">
                <v-switch
                  v-model="edit.tensao01"
                  hide-details
                  class="mt-0"
                  color="secondary"
                  :label="`Tensão 1`"
                ></v-switch>
                <v-switch
                  v-model="edit.tensao02"
                  hide-details
                  class="mt-0"
                  color="secondary"
                  :label="`Tensão 2`"
                ></v-switch>
                <v-switch
                  v-model="edit.tensao03"
                  hide-details
                  class="mt-0"
                  color="secondary"
                  :label="`Tensão 3`"
                ></v-switch>
                <v-switch
                  v-model="edit.tensao04"
                  hide-details
                  class="mt-0"
                  color="secondary"
                  :label="`Tensão 4`"
                ></v-switch>
              </div>
              <div class="col-6 pl-5">
                <v-switch
                  v-model="edit.temperatura01"
                  hide-details
                  class="mt-0"
                  color="secondary"
                  :label="`Temperatura 1`"
                ></v-switch>
                <v-switch
                  v-model="edit.temperatura02"
                  hide-details
                  class="mt-0"
                  color="secondary"
                  :label="`Temperatura 2`"
                ></v-switch>
                <v-switch
                  v-model="edit.temperatura03"
                  hide-details
                  class="mt-0"
                  color="secondary"
                  :label="`Temperatura 3`"
                ></v-switch>
              </div>
            </div>
            <!-- </v-sheet> -->
          </div>
          <div class="row">
            <div class="col-12">
              <div class="d-flex btns">
                <v-btn @click="saveChange" class="mt-5 btnSave" light>
                  Salvar
                </v-btn>
                <v-btn
                  @click="cancel"
                  class="mt-5 btnCancel"
                  color="indigo"
                  dark
                >
                  Cancelar
                </v-btn>
                <v-tooltip v-if="showbtnDB" bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mt-5 btnClearDatabase"
                      color="indigo"
                      dark
                      icon
                      v-on="on"
                    >
                      <v-icon color="#364456 !important" @click="clearDatabase">
                        {{ mdiDatabaseRemove }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Excluir da base dados</span>
                </v-tooltip>
              </div>
            </div>
          </div>
          <app-snackbar
            v-model="showSnackbar"
            :color="msgColor === 'error' ? 'error' : 'green'"
            :text="msg"
          />
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>
import { mdiDatabaseRemove } from "@mdi/js";
import { mdiPlaylistEdit } from "@mdi/js";
import { mapGetters, mapActions } from "vuex";
import { ROUTE_NAME } from "@/router";
import AppSnackbar from "@/components/AppSnackbar";

import axios from "@/config/axios";
export default {
  components: {
    AppSnackbar,
  },
  data() {
    return {
      showSnackbar: false,
      msg: "",
      msgColor: "",
      tensao: {
        tensao01: false,
        tensao02: false,
        tensao03: false,
        tensao04: false,
      },
      switch1: false,
      mdiDatabaseRemove,
      showbtnDB: false,
      loading: false,
      mdiPlaylistEdit,
      navActive: null,
      edit: {
        titulo: "",
        cidade: "",
        estado: "",
        tempoAmostragem: "",
        endereco: "",
      },
      listSite: {},
    };
  },
  methods: {
    editItem(el, index) {
      this.navActive = index;
      this.showbtnDB = true;
      let editItem = {
        titulo: el.title,
        cidade: el.city,
        estado: el.state,
        endereco: el.addr,
        tempoAmostragem: el.sampleTime,
        id: el._id,
        tensao01: el.channelsV[0] === 1 ? true : false,
        tensao02: el.channelsV[1] === 1 ? true : false,
        tensao03: el.channelsV[2] === 1 ? true : false,
        tensao04: el.channelsV[3] === 1 ? true : false,

        temperatura01: el.channelsT[0] === 1 ? true : false,
        temperatura02: el.channelsT[1] === 1 ? true : false,
        temperatura03: el.channelsT[2] === 1 ? true : false,
      };
      this.edit = editItem;
    },
    saveChange() {
      axios
        .post("setsite", this.edit)
        .then(res => {
          if (res) {
            this.loadSites();
            this.showSnackbar = true;
            this.msg = "Alteração realizada com sucesso!";
          }
        })
        .catch(err => {
          console.log(err);
          console.log("ersedr");
          this.showSnackbar = true;
          this.msgColor = "error";
          this.msg = "Ocorreu um erro. tente novamente !";
        });
      // this.edit = {};
      //post
    },
    cancel() {
      this.edit = {};
      this.$router.push({ name: ROUTE_NAME.OVERVIEW });
    },
    clearDatabase() {
      console.log("111");
    },
    ...mapActions(["loadSites"]),
  },
  computed: {
    ...mapGetters(["sites"]),
  },
  async created() {
    this.loading = true;
    if (this.sites.length == 0) {
      try {
        await this.loadSites();
        this.loading = false;
      } catch {
        console.log("erro");
        this.loading = false;
      }
    } else {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.container-edit {
  /* height: calc(100vh - 64px); */
  .siteEdit {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 14px;
    font-weight: 300;
    .navtitle {
      display: flex;
      gap: 6px;
      align-items: center;
      padding: 0px 13px;
      margin: 0rem 0px 1rem;
    }
  }
}
nav {
  margin-bottom: 16px;
  list-style: none;
  /* background-color: #fafafa; */
  border: 1px solid #82878c;
  border-radius: 6px;
}
ul {
  padding-left: 0px !important;
  & a > li {
    list-style: none;
    position: relative;
    display: block;
    color: red;
    padding: 8px 16px;
    color: #24292f;
    border-bottom: 1px solid #82878c;

    &:hover {
      text-decoration: none;
      background-color: #eaeef280;
    }
  }
  .active {
    border-left: 2px solid #0763be80;
    border-radius: 2px;
    visibility: visible;
    opacity: 1;
    transition: opacity 2s linear;
  }
}

.forminputs {
  padding-top: 0;
  margin-top: 1rem !important;
  .btns {
    gap: 1.2rem;
    .btnCancel,
    .btnClearDatabase {
      background-color: initial !important;
    }
  }
}

input[type="text"] {
  width: 100%;
  border: 1px solid #82878c;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
}

input[type="text"]:focus {
  border-color: #a6adb5;
  box-shadow: 0 0 4px 0 #a6adb5;
}
</style>
